import {withRouter} from "react-router";
import React, {useEffect, useState} from "react";
import {Fetch} from "../common/lib/api-fetch";
import {API_BASE_URL} from "../constants";
import {getImageUrl} from "../common/lib/image";
import './Main.css';


const Main = ({history}) => {
    const [data, setData] = useState(null);

    const fetchData = async () => {
        await Fetch(API_BASE_URL+"/prayer/today", "GET").then(result => {
            setData(result.data);
        })
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div className="wrapper">
            {data===null ?
                <div className={"loading"}>Laster</div>
                :
                <>
                    <header className="App-header">
                        <img src={getImageUrl(data.image)} className="prayer-image" alt="Prayer"/>
                    </header>
                    <div className={"content"}>
                    <div className={"bible"}>
                        &ldquo;{data.bibleText}&rdquo;
                        <div className={"bible-site"}>{data.bibleRef}</div>
                    </div>
                    <div className={"prayer"}>Bønn:<br/>{data.prayer}</div>
                    <div className="info" onClick={() => history.push("/info")}>Informasjon om Be i lag</div>
                    </div>
                </>
            }
        </div>
    );
}

export default withRouter(Main);
