import React from "react";
import {withRouter} from "react-router";
import './Info.css';

const Info = ({history}) => {

    return (
        <div className={"wrapper content"}>

            <div className={"title"}>Be i lag</div>

            Be i lag springer ut fra Lye forsamlingshus der man ønsket å samle seg i bønn i en vanskelig tid. Vær med å be sammen hver dag.
            <div className={"bible"}>
                &ldquo;Dersom to av dere her på jorden blir enige om å be om noe, hva det enn er,
                skal de få det av min Far i himmelen. For hvor to eller tre er samlet i mitt navn, der er jeg midt
                iblant dem.&rdquo;
                <div className={"bible-site"}>
                    Matt 18,19f
                </div>
            </div>
            <img src={"/images/hands.jpg"} className="hands" alt="Hender"/>
            Gå til siden https://be-i-lag.no/ for å få opp dagens bønn.

            Bilder og bønnetekster er laget av Vegard Hanssen (https://www.menneske.no)
            <div className={"info"} onClick={() => history.push("/")}>Dagens bønn</div>
            <div className={"info"}><a href={"https://photos.flogvit.com/Be-i-lag/"} target="_blank">Se alle dagene</a></div>
            <div className={"play"}>
            <a href='https://play.google.com/store/apps/details?id=com.bonnnative&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Tilgjengelig på Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/no_badge_web_generic.png'/></a>
            </div>
        </div>
    )
}


export default withRouter(Info);
