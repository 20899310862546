import React from "react";
import {withRouter} from "react-router";
import './Info.css';

const Personvern = ({history}) => {

    return (
        <div className={"wrapper content"}>

            <div className={"title"}>Personvern</div>

            Det blir ikke lagret noe om deg og din aktivitet på denne siden. Trenger du mer informasjon kan du ta kontakt med meg på Vegard.Hanssen@menneske.no.

            <div className={"info"} onClick={() => history.push("/")}>Dagens bønn</div>
        </div>
    )
}


export default withRouter(Personvern);
