import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch} from "react-router";
import Main from "./components/Main";
import Info from "./components/Info";
import Personvern from "./components/Personvern";

function App() {
    return (
        <>
            <Switch>
                <Route path={"/"} exact component={Main}/>
                <Route path={"/info"} exact component={Info}/>
                <Route path={"/personvern"} exact component={Personvern}/>
                <Redirect from='*' to='/' />
            </Switch>
        </>
    );
}

export default App;
